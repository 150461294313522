<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增零售支付类型':'修改零售支付类型'" :close-on-click-modal="false" @closed="cancel" width="420px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-suffix=":" label-width="110px" v-if="form" :disabled="disabled">
        <el-form-item label="类型名称" prop="name">
          <el-input v-model.trim="form.name" :maxlength="20" />
        </el-form-item>
        <el-form-item label="类型编码" prop="code">
          <el-input v-model.trim="form.code" :maxlength="20" />
        </el-form-item>
        <el-form-item label="ERP结算编码" prop="erpCode">
          <el-input v-model.trim="form.erpCode" :maxlength="20" />
        </el-form-item>
        <el-form-item label="ERP收款账号/科目" prop="erpCode">
          <el-input v-model.trim="form.erpSettleAccount" :maxlength="50" />
        </el-form-item>
        <el-form-item label="排序号" prop="displayIndex">
          <el-input-number v-model="form.displayIndex" :min="0" :max="9999" controls-position="right" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/retailPayType";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      disabled: false,
      form: null,
      rules: {
        name: [{ required: "true", message: "类型名称为必填项" }],
        code: [{ required: "true", message: "类型编码为必填项" }]
      }
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    // 点击保存判断是 新增 还是 编辑修改
    doSave() {
      this.loading = true;
      if (this.isAdd) {
        this.doAdd();
      } else this.doEdit();
    },
    // 确认保存
    doAdd() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          add(this.form)
            .then(res => {
              this.$notify({
                title: "保存成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    // 修改
    doEdit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          edit(this.form)
            .then(res => {
              this.$notify({
                title: "修改成功",
                type: "success",
                duration: 2500
              });
              this.loading = false;
              this.dialog = false;
              this.$parent.init();
            })
            .catch(err => {
              this.loading = false;
              console.log(err.response.data.message);
            });
        }
      });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: "",
        code: "",
        erpCode: "",
        displayIndex: 0
      };
    }
  }
};
</script>

